import React from 'react';
import ModalWindow from '../modal-window';
import {useIntl} from 'react-intl';
import {GritxButton} from '@wholesalechange/chatcomponent';
import {ButtonVariant} from '@wholesalechange/chatcomponent';

import './style.scss';

interface IConfirmationModalWindow {
  title: string
  onSave: () => void
  onClose: () => void
  show: boolean
}

export const ConfirmationModalWindow = (
  {
    title,
    onSave,
    onClose,
    show
  }: IConfirmationModalWindow
): JSX.Element => {
  const intl = useIntl();

  function handleClose() {
    onClose();
  }

  function handleSave() {
    onSave();
  }

  const content = () => {
    return <div className={'confirmation-form__container'}>
      <div className={'form-confirmation'}>
        <div className="form-confirmation__header">
          <h2 className="form-confirmation__title">{title}</h2>
        </div>
      </div>
    </div>;
  };

  const formsButtons = () => {
    return <div className="confirmation-form__footer">
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.no',
          defaultMessage: 'No'
        })}
        variant={ButtonVariant.Light}
        className="confirmation-form__button"
        onClick={handleClose}
      />
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.yes',
          defaultMessage: 'Yes'
        })}
        variant={ButtonVariant.Primary}
        className="confirmation-form__button"
        onClick={handleSave}
      />
    </div>;
  };

  return <ModalWindow
    show={show}
    className="confirmation-form__modal"
    onHide={handleClose}
    footerElement={formsButtons()}
  >
    {content()}
  </ModalWindow>;
};
