import React, {FunctionComponent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {loadArticleAction} from 'redux/content/contentAsyncActions';
import {IStore} from 'redux/interface';

import {BotNameMode, ButtonVariant} from '@wholesalechange/chatcomponent';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';
import {useLoadFile} from 'utils/hooks/useLoadFile';

import {XpeditionList} from './xpedition/XpeditionList';
import {BotContent} from './BotContent';
import {BotActionType, BotChat as Chat, BotMode, MessageType, BotType, GritxButton, IBotAction} from '@wholesalechange/chatcomponent';
import {
  contentAsyncActions,
  dialogueAsyncActions,
  getArticle,
  getDialogueParticipant,
  getXpeditions
} from '../../redux/pageAsyncActions';
import {useDictionary} from '../../utils/hooks/useDictionary';
import {useChatBotNew} from '../../utils/hooks/useChatBotNew';
import Loader from '../loader';

import './styles.scss';
import {useNavigate} from 'react-router-dom';
import NotFoundInfo from '../not-found-info';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import {closeArticle} from '../../redux/content/actions';

interface IBotChat {
  mode: BotType,
  dialogueId?: number
}

export const BotChat: FunctionComponent<IBotChat> = ({mode, dialogueId}: IBotChat) => {
  const intl = useIntl();

  const [isShowContent, setIsShowContent] = useState(true);
  const botMode = BotMode.NoHistory;
  const chatBot = useChatBotNew(mode, botMode);
  const history = useNavigate();

  const {
    status: {loading},
    content: {article},
    auth: {userProfile},
    dialogue: {participant, xpeditions}
  } = useSelector((state: IStore) => state);

  const dictionary = useDictionary();

  const dispatch = useDispatch();
  const {getFileUrl, getFileUrlAsync} = useLoadFile();

  useEffect(() => {
    if (chatBot.currentDialog && userProfile) {
      dispatch(dialogueAsyncActions[getDialogueParticipant](userProfile.id, chatBot.currentDialog.id));
    }
  }, [userProfile, chatBot.currentDialog]);

  useEffect(() => {
    if (mode === BotType.Xpedition && dialogueId) {
      chatBot.xpedition.setXpeditionDialogue(dialogueId);
    }
  }, [dialogueId]);

  useEffect(() => {
    chatBot.dialogueMethods.getMainDialog();
  }, []);

  const pushUserMessage = (text: string) => {
    if (chatBot.currentDialog && participant) {
      chatBot.sendMessage(MessageType.Text, participant, {text});
    }
  };

  function getBotMode(type: BotType): BotMode {
    return type && type === BotType.Xpedition ? BotMode.NoHistory : BotMode.FullHistory;
  }

  const handleSendMessage = (message: string) => {
    pushUserMessage(message.trim());
  };

  async function loadMore() {
    if (chatBot.currentDialog && !chatBot.isLoading) {
      await chatBot.dialogueMethods.fetchMore();
    }
  }

  function handleShowArticle(code: string) {
    setIsShowContent(true);
    if (article && article.id.toString() === code) {
      return;
    }
    dispatch(contentAsyncActions[getArticle](code));
  }

  function handleShowExternalOverlay() {
    if (article) {
      dispatch(closeArticle());
    }
    setIsShowContent(false);
  }

  useEffect(() => {
    if (mode === BotType.XpeditionList) {
      dispatch(dialogueAsyncActions[getXpeditions]());
    } else if (mode !== BotType.Xpedition && userProfile?.greetingContentId) {
      dispatch(loadArticleAction(userProfile.greetingContentId.toString()));
      setIsShowContent(true);
    }
  }, [userProfile]);

  function handleSendOverlay(actionButton: IBotAction | null) {
    if (!chatBot.currentDialog || !participant) {
      return;
    }
    // setIsShowXpeditionOverlay(false);
    if (!actionButton) {
      return;
    }

    // setXpeditionOverlayAnswer(null);
    chatBot.sendMessage(MessageType.Action, participant, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleClickAction(actionButton: IBotAction) {
    if (!chatBot.currentDialog || !participant) {
      return;
    }
    const actionBody = JSON.parse(actionButton.actionBody);

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setIsShowContent(true);
        chatBot.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
        // setIsShowXpeditionOverlay(false);
        chatBot.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleStartXpedition(botId: number) {
    history(`/xpedition/${botId}`);
  }

  async function handleRemoveDialog(dialogId: number) {
    await chatBot.dialogueMethods.removeDialog(dialogId);
    dispatch(dialogueAsyncActions[getXpeditions]());
  }

  function getBotAvatar() {
    return userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon;
  }

  function renderButtons() {
    return <GritxButton
      title={intl.formatMessage({
        id: 'gritx.chat.helpButton',
        defaultMessage: 'Help'
      })}
      variant={ButtonVariant.Outline}
      className="dialogue-chat__help-btn"
      onClick={handleHelp}
    />;
  }

  function renderBotContent() {
    return isShowContent ? <BotContent botAnswer={chatBot.botAnswer}/> : null;
  }
  // ----------

  if (loading.includes(getXpeditions)) {
    return <Loader nested/>;
  }

  if (mode === BotType.XpeditionList) {
    if (xpeditions.length === 0) {
      return <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.xpedition.notFound',
          defaultMessage: 'No Xpeditions Added Yet'
        })}
        image={CrossroadImg}
      />;
    }
    return <XpeditionList
      onStart={handleStartXpedition}
      onRemove={handleRemoveDialog}
    />;
  }

  if (chatBot.dialogIsNotSet) {
    return <NotFoundInfo
      title={intl.formatMessage({
        id: 'gritx.chat.notFound',
        defaultMessage: 'Dialogue has not been added yet'
      })}
      image={CrossroadImg}
    />;
  }

  if (chatBot.currentDialog) {
    return <div className="bot">
      <div className="bot__wrapper">
        {userProfile && <Chat isLoading={chatBot.isLoading}
          isTyping={chatBot.isTyping}
          chatAvatar={getBotAvatar()}
          toolbarButtons={[renderButtons()]}
          messages={chatBot.messages || []}
          onSend={handleSendMessage}
          onSendOverlay={handleSendOverlay}
          onClickAction={handleClickAction}
          currentUser={userProfile}
          onLoadMore={loadMore}
          onShowArticle={handleShowArticle}
          onShowExternalOverlay={handleShowExternalOverlay}
          dictionary={dictionary}
          botMode={botMode}
          botType={mode}
          isConnected={chatBot.isConnected}
          botNameMode={BotNameMode.BOT}
          getFileUrl={getFileUrlAsync}/>}
        {renderBotContent()}
      </div>
    </div>;
  }
  return <></>
};
