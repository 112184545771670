import {IDialogueCard, IVideoCall} from '../../redux/dialogue/interfaces';
import React, {ChangeEvent, FunctionComponent, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './styles.scss';
import {
  CallbacksInterface,
  RtcPropsInterface,
  RtmPropsInterface,
  StylePropInterface
} from 'agora-react-uikit/dist/PropsContext';
import AgoraUIKit from 'agora-react-uikit';
import Loader from '../loader';
import {useDialogue} from '../../utils/hooks/useDialogue';
import {GritxButton} from '@wholesalechange/chatcomponent';
import {ButtonVariant} from '@wholesalechange/chatcomponent';
import {useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {GritxSelect} from '../gritx-select/GritxSelect';
import {IVideoCallSettings, useVideoWebSocket} from '../../utils/hooks/useVideoWebSocket';
import EditIcon from 'assets/image/dialogue/edit-3.svg';
import LoadingIcon from 'assets/image/dialogue/loader.svg';
import axios from 'axios';
import {StompHeaders} from '@stomp/stompjs';
import {useVideoSpeechRecognition} from '../../utils/hooks/useVideoSpeechRecognition';

interface IDialogueVideo {
  dialogue: IDialogueCard,
  isHost: boolean,
  onLeaveVideoCall: () => void
  onStartVideoCall: () => void
}

export const DialogueVideo: FunctionComponent<IDialogueVideo> = ({dialogue, isHost, onLeaveVideoCall, onStartVideoCall}: IDialogueVideo) => {
  const [rtcProps, setRtcProps] = useState<RtcPropsInterface>();
  const [rtmProps, setRtmProps] = useState<RtmPropsInterface>();
  const [callbacks, setCallbacks] = useState<Partial<CallbacksInterface>>();
  const [isShowVideo, setIsShowVideo] = useState<boolean>(false);
  const [isShowVideoMessage, setIsShowVideoMessage] = useState<boolean>(false);
  const [videoCall, setVideoCall] = useState<IVideoCall|null>();
  const [speechRecognition, setSpeechRecognition] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [languages, setLanguages] = useState<{ id: string, name: string }[]>();

  const intl = useIntl();
  const {
    auth: {userProfile},
    dialogue: {participant}
  } = useSelector((state: IStore) => state);
  const videoDialogue = useDialogue();

  const onMessage = (msg: IVideoCallSettings, topic: string) => {
    if (topic === `/topic/video/${msg.videoCallId}/reply`) {
      setSpeechRecognition(msg.speechRecognition);
      setVideoCall(prevState => {
        return prevState ? {
          ...prevState,
          language: msg.language
        } : undefined;
      });
    }
  };

  const props = {
    url: process.env.REACT_APP_WEBSOCKET_URL || '',
    headers: axios.defaults.headers.common as StompHeaders,
    subscribeHeaders: axios.defaults.headers.common as StompHeaders,
    topics: ['/user/queue/errors'],
    onMessage: onMessage,
    options: {},
    heartbeat: 1000,
    heartbeatIncoming: 1000,
    heartbeatOutgoing: 1000,
    debug: true,
    onConnect: () => {
      console.log('onConnect');
    },
    onConnectFailure: (error: string) => {
      console.log('onConnectFailure');
    },
    onDisconnect: () => {
      console.log('onDisconnect');
    },
    autoReconnect: true,
    getRetryInterval: (count: number) => { return 1000 * count; }
  };
  const webSocket = useVideoWebSocket(props);

  const onTranscriptChange = (transcript: string) => {
    if (transcript && transcript !== '' && participant) {
      webSocket.sendMessage('TEXT', dialogue.id, videoCall?.id, transcript, participant, false);
    }
  };

  const videoSpeechRecognition = useVideoSpeechRecognition({onResult: onTranscriptChange});

  function handleLeaveVideoCall(videoCallId: number | undefined) {
    if (isHost && videoCallId && participant) {
      videoDialogue.finishVideoCall(videoCallId);

      webSocket.sendMessage('VIDEO', dialogue.id, videoCallId, 'Video call was finished', participant, true);
      webSocket.disconnect();
      if (speechRecognition && !audioMuted) {
        videoSpeechRecognition.stopListening();
      }
    }
    setVideoCall(null);
    setIsShowVideo(false);
    setIsShowVideoMessage(false);
    onLeaveVideoCall();
  }

  function handleMuteAudio(state: number) {
    setAudioMuted(state !== 1);
  }

  useEffect(() => {
    if (speechRecognition && videoCall && videoCall.language) {
      if (audioMuted) {
        videoSpeechRecognition.stopListening();
      } else {
        videoSpeechRecognition.startListening(videoCall.language);
      }
    }
  }, [audioMuted]);

  async function fillRtmToken() {
    if (!userProfile) {
      return;
    }

    const rtmToken = await videoDialogue.getVideoCallRtmToken(userProfile.id.toString());

    if (rtmToken) {
      const rtm: RtmPropsInterface = {
        username: userProfile.firstName,
        showPopUpBeforeRemoteMute: !isHost,
        displayUsername: true,
        token: rtmToken.value,
        uid: userProfile.id.toString()
      };

      setRtmProps(rtm);
    }
  }

  function initLanguages(video: IVideoCall) {
    const langs = video.languages.map(lang => {
      return {
        id: lang, name: lang
      };
    });

    setLanguages(langs);
  }

  async function initVideoWebSocket(video: IVideoCall) {
    if (userProfile && participant) {
      const channel = `dialogue_${dialogue.id}`;
      const token = await videoDialogue.getVideoCallToken(channel);

      if (!token) {
        return;
      }
      const rtc: RtcPropsInterface = {
        appId: process.env.REACT_APP_AGORA_APP_ID || '',
        channel: channel,
        token: token.value,
        layout: 1,
        activeSpeaker: false,
        uid: userProfile.id
      };

      setRtcProps(rtc);

      await fillRtmToken();

      setCallbacks({
        EndCall: () => handleLeaveVideoCall(video?.id),
        ['local-user-mute-audio']: (status: number) => handleMuteAudio(status)
      });

      // websocket connect
      webSocket.subscribe(`/topic/video/${video?.id}/reply`);
      if (isHost) {
        webSocket.sendMessage('VIDEO', dialogue.id, video?.id, 'Video call was started', participant, true);
      }

      setIsShowVideoMessage(false);
      setIsShowVideo(true);
      onStartVideoCall();
    }
  }

  async function startVideoCall() {
    let video: IVideoCall | null = null;

    video = await videoDialogue.createVideoCall(dialogue.id);
    if (!video) {
      return;
    }
    setVideoCall(video);
    initLanguages(video);

    const isActive = await videoDialogue.getVideoCallActive(dialogue.id);

    if (isActive && userProfile && participant) {
      initVideoWebSocket(video);
      setSpeechRecognition(video.speechRecognition);
    } else if (!isHost) {
      setIsShowVideoMessage(true);
    }
  }

  useEffect(() => {
    if (webSocket.connected && !videoCall) {
      startVideoCall();
    }
  }, [webSocket.connected]);

  useEffect(() => {
    if (speechRecognition && videoCall && videoCall.language) {
      videoSpeechRecognition.startListening(videoCall.language);
    } else {
      videoSpeechRecognition.stopListening();
    }
  }, [speechRecognition]);

  function handleStartSpeechRecognition() {
    setSpeechRecognition(true);

    if (videoCall && webSocket) {
      webSocket.changeVideoCallSettings(videoCall.id, true, videoCall.language);
    }
  }

  function handleStopSpeechRecognition() {
    setSpeechRecognition(false);

    if (videoCall && webSocket) {
      webSocket.changeVideoCallSettings(videoCall.id, false, videoCall.language);
    }
  }

  const styleProps: Partial<StylePropInterface> = {
    localBtnContainer: {backgroundColor: 'inherit', height: '78px'},
    usernameText: {backgroundColor: '#676666'},
    maxViewContainer: {backgroundColor: '#6f7c7d', flex: '3 1 0%'},
    minViewContainer: {backgroundColor: '#676666', minHeight: '40%'},
    localBtnStyles: {
      muteLocalAudio: {backgroundColor: '#6f7c7d', borderColor: '#676666'},
      muteLocalVideo: {backgroundColor: '#6f7c7d', borderColor: '#676666'},
      endCall: {backgroundColor: '#fc767d', borderColor: '#f8454e'}
    }
  };

  const handleSelectLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    videoDialogue.setDialogueLanguage(dialogue.id, e.target.value)
      .then((response) => {
        if (videoCall) {
          const video: IVideoCall = {
            ...videoCall,
            language: e.target.value
          };

          setVideoCall(video);

          if (videoCall && webSocket) {
            webSocket.changeVideoCallSettings(videoCall.id, speechRecognition, video.language);
            if (speechRecognition && !audioMuted) {
              videoSpeechRecognition.stopListening();
              videoSpeechRecognition.startListening(video.language);
            }
          }
        }
      });
  };

  function isLoading() {
    return videoDialogue.isLoading || webSocket.loading;
  }

  function renderHostSpeechButtons() {
    return <>
      {isHost && !speechRecognition ? <GritxButton
        title={intl.formatMessage({
          id: 'gritx.video.speech.recognition.start',
          defaultMessage: 'Start speech recognition'
        })}
        variant={ButtonVariant.Outline}
        className="video__button"
        onClick={handleStartSpeechRecognition}
      /> : <></>}
      {isHost && speechRecognition && videoSpeechRecognition.listening ? <GritxButton
        title={intl.formatMessage({
          id: 'gritx.video.speech.recognition.stop',
          defaultMessage: 'Stop speech recognition'
        })}
        variant={ButtonVariant.Outline}
        className="video__button"
        onClick={handleStopSpeechRecognition}
      /> : <></>}
    </>;
  }

  function renderSpeechButtons() {
    return <>
      {renderHostSpeechButtons()}
      {!isHost && speechRecognition && videoSpeechRecognition.listening ? <img src={EditIcon} alt="" className="video__image"
        title={intl.formatMessage({
          id: 'gritx.video.speech.recognition.title',
          defaultMessage: 'Speech recognition is on'
        })}/> : <></>}
      {speechRecognition && videoSpeechRecognition.loading ? <img src={LoadingIcon} alt="" className="video__loading"/> : <></>}
    </>;
  }

  function renderVideoToolbar() {
    return <div className="video__toolbar">
      <div className="video__toolbar-start">
        {renderSpeechButtons()}
        {isHost && languages ? <GritxSelect
          name={'language'}
          options={languages}
          placeholder={intl.formatMessage({
            id: 'gritx.video.select.language',
            defaultMessage: 'Please, select dialogue language'
          })}
          defaultValue={videoCall?.language}
          onChange={handleSelectLanguage}
          onBlur={handleSelectLanguage}
          className='video__language-selector'
        /> : <></>}
      </div>
      <div className="video__toolbar-end">
        <div className="video__language">
          {videoCall?.language}
        </div>
      </div>
    </div>;
  }

  return <>
    {isLoading() && <Loader/>}
    {!isLoading() && isShowVideoMessage
      ? <div className='video__message'>
        <p>
          <FormattedMessage
            id={'gritx.chat.videocall.not.started'}
            defaultMessage={'A video call has not been started yet.'}
          />
        </p>
      </div>
      : <></>
    }
    {!isLoading() && isShowVideo
      ? <div className="video">
        <div className="video__header">
          {renderVideoToolbar()}
        </div>
        <div className="video__wrapper">
          <div className="video__videocall">
            <div id="video" className='video__videos'>
              {rtcProps
                && <AgoraUIKit rtcProps={rtcProps}
                  rtmProps={rtmProps}
                  callbacks={callbacks}
                  styleProps={styleProps}/>}
            </div>
          </div>
        </div>
      </div>
      : <></>
    }
  </>;
};
