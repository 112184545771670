import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {IFrame} from '@stomp/stompjs/src/i-frame';
import {CompatClient, Stomp} from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {StompSubscription} from '@stomp/stompjs/esm6/stomp-subscription';
import {StompHeaders} from '@stomp/stompjs/src/stomp-headers';
import {messageCallbackType} from '@stomp/stompjs/src/types';

interface IUseWebsocket {
  isConnected: boolean
  isConnecting: boolean
  sendMessage: (msg: string) => void
  subscribe: (dialogueId: number, callback: messageCallbackType) => void
  disconnect: () => void
}

export const useWebsocket = (): IUseWebsocket => {
  const ref = useRef(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [compatClient, setCompatClient] = useState<CompatClient>();
  const [retryCount, setRetryCount] = useState<number>(0);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>();
  const [subscriptions, setSubscriptions] = useState<{[key: string]: StompSubscription}>({});
  const [explicitDisconnect, setExplicitDisconnect] = useState<boolean>(false);

  function initStompClient(): CompatClient {
    const client = Stomp.over(new SockJS(process.env.REACT_APP_WEBSOCKET_URL || '', null, {}));

    client.heartbeat.outgoing = 1000;
    client.heartbeat.incoming = 1000;

    setCompatClient(client);

    return client;
  }

  function cleanUp() {
    setIsConnected(false);
    setRetryCount(0);
    setSubscriptions({});
  }

  function unsubscribe(topic: string) {
    const sub = subscriptions[topic];

    sub.unsubscribe();
    delete subscriptions[topic];
    setSubscriptions(subscriptions);
  }

  function disconnect() {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setExplicitDisconnect(true);
    if (isConnected && compatClient) {
      Object.keys(subscriptions).forEach(topic => {
        unsubscribe(topic);
      });
      compatClient.disconnect(() => {
        cleanUp();
      });
    }
  }

  function getRetryInterval(count: number) { return 1000 * count; }

  function connect() {
    setIsConnecting(true);
    const client = initStompClient();

    client.onConnect = () => {
      setIsConnected(true);
      setIsConnecting(false);
    };

    client.connect(axios.defaults.headers.common, () => {
      setIsConnected(true);

      setIsConnecting(false);
    }, (error: IFrame) => {
      console.log(error.body);
      if (isConnected) {
        cleanUp();
      }
      const retry = retryCount + 1;

      setRetryCount(retry);
      // const timeout = setTimeout(connect, getRetryInterval(retry));

      // setTimeoutId(timeout);

      setIsConnecting(false);
    });

    client.onWebSocketClose = () => {
      disconnect();
      if (!explicitDisconnect) {
        setTimeout(connect, 5000);
      }
    };
  }

  async function subscribe(dialogueId: number, callback: messageCallbackType) {
    if (compatClient && !subscriptions.topic) {
      const sub = compatClient.subscribe(`/topic/dialogue/${dialogueId}/reply`, callback, axios.defaults.headers.common as StompHeaders);

      setSubscriptions({...subscriptions, topic: sub});
    }
  }

  function sendMessage(msg: string) {
    const sendHeaders: StompHeaders = {...axios.defaults.headers.common as StompHeaders};

    if (compatClient && isConnected) {
      compatClient.send('/app/message', sendHeaders, msg);
    } else {
      throw new Error('Send error: SockJsClient is disconnected');
    }
  }

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;

      connect();
    }
  }, []);

  return {
    isConnected,
    isConnecting,
    sendMessage,
    subscribe,
    disconnect
  };
};
