import React, {useEffect, useState} from 'react';

import './styles.scss';
import {useLoadFile} from '../../utils/hooks/useLoadFile';
import {IBot} from '../../redux/dialogue/interfaces';

interface IBotItemProps {
  item: IBot
  addBot: (botId: number) => void;
}

export const BotItem = ({item, addBot}: IBotItemProps): JSX.Element => {
  const {
    getFileUrl
  } = useLoadFile();
  const [avatarIcon, setAvatarIcon] = useState<string>('');

  useEffect(() => {
    if (item.avatar) {
      getFileUrl(item.avatar, (url) => {
        setAvatarIcon(url);
      });
    }
  }, [item]);

  function handleAddBot() {
    addBot(item.id);
  }

  return (
    <div className="bots__avatars">
      <button className="bots__item" onClick={handleAddBot}>
        <img className="bots__img" src={avatarIcon} alt={item.name}/>
      </button>
      <div className="bots__name">{item.name}</div>
    </div>
  );
};
