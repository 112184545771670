import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {loadMediaAction} from 'redux/media/mediaAsyncActions';
import {IMediaInitialState} from 'redux/media/interfaces';
import {getMediaUrlApi} from '../../redux/api/media';

interface IUseLoadFile {
  media: IMediaInitialState
  getFileUrl: (fileId: number, callback?: (url: string) => void) => string
  getFileUrlAsync: (fileId: number) => Promise<string>
}

export function useLoadFile(): IUseLoadFile {
  const {
    media,
    status: {loading}
  } = useSelector((store: IStore) => store);
  const dispatch = useDispatch();

  function getFileUrl(fileId: number, callback?: (url: string) => void): string {
    if (media[fileId]) {
      if (callback) {
        callback(media[fileId]);
      }

      return media[fileId];
    }
    if (!loading.includes(fileId.toString())) {
      dispatch(loadMediaAction(fileId, callback));
    }

    return '';
  }

  async function getFileUrlAsync(fileId: number): Promise<string> {
    if (media[fileId]) {
      return media[fileId];
    }

    const {data: {value: fileUrl} } = await getMediaUrlApi(fileId);

    return fileUrl;
  }

  return {
    getFileUrl,
    getFileUrlAsync,
    media
  };
}
