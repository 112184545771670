import React, {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import cn from 'classnames';

import 'react-phone-number-input/style.css';
import './styles.scss';

interface IInputPhoneProps {
  labelClassName: string,
  userPhoneNumber: string,
  onChange: (value: string) => void,
}

export const InputPhone = (
  {
    labelClassName,
    userPhoneNumber,
    onChange
  }: IInputPhoneProps
): JSX.Element => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>(userPhoneNumber || '');

  const ref = useRef(null);
  const input = ref.current as HTMLInputElement | null;

  function handleChangeValue(value: string) {
    if (!value && !input?.value) {
      onChange('');
      setPhoneValue('');
    }
    if (value && input?.value && isValidPhoneNumber(value)) {
      setPhoneValue(value);
      onChange(value);
    } else {
      onChange(input?.value || '');
      setPhoneValue(value);
    }
  }

  function showValidateMessage() {
    if (phoneValue && !isValidPhoneNumber(phoneValue)) {
      setShowErrorMessage(true);
    }
    if (!phoneValue && input?.value) {
      setShowErrorMessage(true);
    }
  }

  function hideValidateMessage() {
    setShowErrorMessage(false);
  }

  return (
    <div className="phone">
      <label
        className={cn('phone__label', labelClassName)}
        htmlFor="phone-input"
      >
        <FormattedMessage
          id="gritx.user-settings.phone"
          defaultMessage="Phone"
        />
      </label>
      <PhoneInput
        id="phone-input"
        ref={ref}
        className={cn('phone-input', {'phone-input--error': showErrorMessage})}
        value={phoneValue}
        placeholder={'+1 000 000-00-00'}
        countryOptionsOrder={['US', 'SG', '|', '...']}
        maxLength={18}
        limitMaxLength={true}
        international
        onChange={handleChangeValue}
        onFocus={hideValidateMessage}
        onBlur={showValidateMessage}
      />
      <div className="phone__message">
        {showErrorMessage && (<span className="phone__message--error">
          <FormattedMessage
            id="gritx.user-settings.phone.messageSuccess"
            defaultMessage="The phone number is invalid"
          />
        </span>)
        }
      </div>
    </div>
  );
};
