import axios, {AxiosResponse} from 'axios';
import {IBot, IBotUser, IDialogueCard, IParticipant, IVideoCall, IVideoCallToken} from '../dialogue/interfaces';
import {IXpedition} from '../../utils/hooks/use-chat-bot/interfaces';

export const getDialoguesApi = (locale: string): Promise<AxiosResponse<IDialogueCard[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dialog/list`,
    {
      params: {
        language: locale,
        typeDialogue: 2
      }
    }
  );
};

export const getDialogueApi = (id: number, locale: string): Promise<AxiosResponse<IDialogueCard>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dialog/${id}`
  );
};

export const setDialogueNameApi = (dialogue: IDialogueCard): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/dialog/set/name`,
    {
      dialogueId: dialogue.id,
      dialogueName: dialogue.name
    }
  );
};

export const createDialogueApi = (dialogue: IDialogueCard): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/dialog/add`,
    {
      dialogueName: dialogue.name,
      typeDialogueId: 2
    }
  );
};

export const removeDialogueApi = (id: number): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/dialog/remove`,
    {
      dialogueId: id
    }
  );
};

export const getDialogueParticipantsApi = (dialogueId: number): Promise<AxiosResponse<IParticipant[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/participant/list/${dialogueId}`
  );
};

export const getDialogueParticipantApi = (userId: number, dialogId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/participant/user/${userId}/${dialogId}`
  );
};

export const getBotListApi = (): Promise<AxiosResponse<IBot[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/bot/list`
  );
};

export const addBotToDialogueApi = (botId: number, dialogueId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/participant/create/bot`,
    {
      botId: botId,
      dialogId: dialogueId
    }
  );
};

export const addUsersToDialogueApi = (emails: string, dialogueId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/participant/create/users`,
    {
      emails: emails,
      dialogId: dialogueId
    }
  );
};

export const removeParticipantFromDialogueApi = (participantId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/participant/remove`,
    {
      participantId: participantId
    }
  );
};

export const blockParticipantApi = (participantId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/participant/block`,
    {
      participantId: participantId
    }
  );
};

export const unlockParticipantApi = (participantId: number): Promise<AxiosResponse<IParticipant>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/participant/unlock`,
    {
      participantId: participantId
    }
  );
};

export const startXpeditionApi = (xpeditionId: number, locale: string): Promise<AxiosResponse<{ value: number }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/start`,
    {
      xpeditionId: xpeditionId,
      language: locale
    }
  );
};

export const restartXpeditionApi = (xpeditionId: number, locale: string): Promise<AxiosResponse<{ value: number }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/restart`,
    {
      xpeditionId: xpeditionId,
      language: locale
    }
  );
};

export const getXpeditionsApi = (locale: string): Promise<AxiosResponse<IXpedition[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/list`,
    {
      params: {
        showInFeed: true,
        language: locale
      }
    }
  );
};

