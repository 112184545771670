import {
  BOTS_LOADED, DIALOGUE_CLEARED,
  DIALOGUE_LOADED, PARTICIPANT_LOADED,
  PARTICIPANTS_LOADED,
  SET_DIALOGUES, USERS_LOADED, XPEDITIONS_LOADED
} from './actions';
import {
  IDialogueActionHandler,
  IDialogueInitialState,
  IDialogueCard,
  IParticipant,
  IBot,
  IBotUser
} from './interfaces';
import {DialogueActionsType} from './actions';
import {IXpedition} from '../../utils/hooks/use-chat-bot/interfaces';

const DialogueInitialState: IDialogueInitialState = {
  dialogues: [],
  dialogue: null,
  participant: null,
  participants: [],
  bots: [],
  users: [],
  xpeditions: []
};

const setDialogues = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    dialogues: payload as IDialogueCard[]
  };
};

const setDialogue = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    dialogue: payload as IDialogueCard
  };
};

const clearDialogue = ({
  state
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    dialogue: null
  };
};

const setParticipants = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    participants: payload as IParticipant[]
  };
};

const setParticipant = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    participant: payload as IParticipant
  };
};

const setBots = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    bots: payload as IBot[]
  };
};

const setUsers = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    users: payload as IBotUser[]
  };
};

const setXpeditions = ({
  state,
  payload
}: IDialogueActionHandler): IDialogueInitialState => {
  return {
    ...state,
    xpeditions: payload as IXpedition[]
  };
};

const statusReducerHandlers = new Map([
  [SET_DIALOGUES, setDialogues],
  [DIALOGUE_LOADED, setDialogue],
  [PARTICIPANT_LOADED, setParticipant],
  [PARTICIPANTS_LOADED, setParticipants],
  [BOTS_LOADED, setBots],
  [XPEDITIONS_LOADED, setXpeditions],
  [USERS_LOADED, setUsers],
  [DIALOGUE_CLEARED, clearDialogue]
]);

const contentReducer = (state = DialogueInitialState, action: DialogueActionsType): IDialogueInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IDialogueActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default contentReducer;
