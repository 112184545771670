import React, {useEffect, useState} from 'react';
import {IParticipant} from '../../redux/dialogue/interfaces';
import UserIcon from 'assets/image/user-settings/userDefaultAvatar.png';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';
import {useLoadFile} from '../../utils/hooks/useLoadFile';
import BlockIcon from '../../assets/image/dialogue/lock.svg';
import UnlockIcon from '../../assets/image/dialogue/unlock.svg';

interface IParticipantCard {
  participant: IParticipant
  onRemove: (participantId: number) => void
  onBlock: (participantId: number) => void
  onUnlock: (participantId: number) => void
}

export const ParticipantCard: React.FunctionComponent<IParticipantCard> = ({participant, onRemove, onBlock, onUnlock}: IParticipantCard) => {
  const {
    getFileUrl
  } = useLoadFile();

  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    if (participant.participantAvatar) {
      getFileUrl(participant.participantAvatar, (url: string) => {
        setAvatar(url);
      });
    } else {
      const defaultIcon = participant.partRoleId === 1 ? BotIcon : UserIcon;

      setAvatar(defaultIcon);
    }
  }, []);

  function handleRemove() {
    onRemove(participant.participantId);
  }

  function handleBlock() {
    onBlock(participant.participantId);
  }

  function handleUnlock() {
    onUnlock(participant.participantId);
  }

  function isBlocked() {
    return participant.status === 'BLOCKED';
  }

  return <div className="participant-card">
    <div className="participant-card__avatar">
      <img src={avatar} role="presentation" alt="" className="participant-card__image"/>
    </div>
    <div className="participant-card__name"> {participant.participantName} </div>
    {!isBlocked() ? <button className={'participant-card__btn'} onClick={handleBlock}><img src={UnlockIcon} alt=""/></button> : <></>}
    {isBlocked() ? <button className={'participant-card__btn'} onClick={handleUnlock}><img src={BlockIcon} alt=""/></button> : <></>}
    <button className={'participant-card__btn'} onClick={handleRemove}>x</button>
  </div>;
};
