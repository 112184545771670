import {IBot, IBotUser, IDialogueCard, IParticipant} from './interfaces';
import {IAction} from '../interface';
import {InferActionsTypes} from '../helpers';
import {IXpedition} from '../../utils/hooks/use-chat-bot/interfaces';

export const SET_DIALOGUES = 'SET_DIALOGUES';
export const setDialogues = (dialogues: IDialogueCard[]): IAction<IDialogueCard[]> => ({
  type: SET_DIALOGUES,
  payload: dialogues
});
export const PARTICIPANTS_LOADED = 'PARTICIPANTS_LOADED';
export const participantsLoadedAction = (participants: IParticipant[]): IAction<IParticipant[]> => ({
  type: PARTICIPANTS_LOADED,
  payload: participants
});
export const PARTICIPANT_LOADED = 'PARTICIPANT_LOADED';
export const participantLoadedAction = (participant: IParticipant): IAction<IParticipant> => ({
  type: PARTICIPANT_LOADED,
  payload: participant
});
export const DIALOGUE_LOADED = 'DIALOGUE_LOADED';
export const dialogueLoadedAction = (dialogue: IDialogueCard): IAction<IDialogueCard> => ({
  type: DIALOGUE_LOADED,
  payload: dialogue
});
export const DIALOGUE_CLEARED = 'DIALOGUE_CLEARED';
export const dialogueClearedAction = (): IAction<null> => ({
  type: DIALOGUE_CLEARED,
  payload: null
});
export const BOTS_LOADED = 'BOTS_LOADED';
export const botsLoadedAction = (bots: IBot[]): IAction<IBot[]> => ({
  type: BOTS_LOADED,
  payload: bots
});
export const USERS_LOADED = 'USERS_LOADED';
export const usersLoadedAction = (users: IBotUser[]): IAction<IBotUser[]> => ({
  type: USERS_LOADED,
  payload: users
});
export const XPEDITIONS_LOADED = 'XPEDITIONS_LOADED';
export const xpeditionsLoadedAction = (xpeditions: IXpedition[]): IAction<IXpedition[]> => ({
  type: XPEDITIONS_LOADED,
  payload: xpeditions
});

const dialogueActions = {
  setDialogues,
  dialogueLoadedAction,
  participantsLoadedAction,
  participantLoadedAction,
  botsLoadedAction,
  usersLoadedAction,
  dialogueClearedAction,
  xpeditionsLoadedAction
};

export type DialogueActionsType = InferActionsTypes<typeof dialogueActions>
