import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {
  createDialogue,
  dialogueAsyncActions,
  getDialogues,
  getTranslations,
  removeDialogue
} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import {ReactComponent as ChatIcon} from 'assets/image/menu-auth/chat.svg';

import './styles.scss';
import {ButtonVariant} from '@wholesalechange/chatcomponent';
import {GritxButton} from '@wholesalechange/chatcomponent';
import NotFoundInfo from '../../components/not-found-info';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import {DialoguesCardSection} from '../../components/dialogues-card-section/DialoguesCardSection';
import Loader from '../../components/loader';
import {DialogueModalWindow} from '../../components/dialogue-modal-window/DialogueModalWindow';
import {IDialogueCard} from '../../redux/dialogue/interfaces';
import {useNavigate} from 'react-router-dom';

export const Dialogues: React.FunctionComponent = () => {
  const {
    dialogue: {
      dialogues
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [newDialogue, setNewDialogue] = useState<IDialogueCard>({
    id: 0,
    name: '',
    avatar: 0,
    unreadCount: 0
  });
  const [isShowCreateDialogue, setShowCreateDialogue] = useState(false);

  useGetTranslations({
    translationKey: TranslationKeys.Dialogues
  });

  useEffect(() => {
    dispatch(dialogueAsyncActions[getDialogues]());
  }, []);

  function handleCreateDialogue(dialogue: IDialogueCard) {
    dispatch(dialogueAsyncActions[createDialogue](dialogue));
    setNewDialogue({
      id: 0,
      name: '',
      avatar: 0,
      unreadCount: 0
    });
    setShowCreateDialogue(false);
  }

  function handleOpenDialogue(id: number) {
    history(`/dialogue/${id}`);
    // history.push(
    //   `/dialogue#${id}`,
    //   history.location.state ? history.location.state : {parentLocation: history.location.pathname}
    // );
  }

  function onCreateDialogue() {
    setShowCreateDialogue(true);
  }

  function handleCloseDialogue() {
    setNewDialogue({
      id: 0,
      name: '',
      avatar: 0,
      unreadCount: 0
    });
    setShowCreateDialogue(false);
  }

  function renderDialoguesCards() {
    return <div className="dialogue__cards">
      {dialogues.length === 0 ? <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.dialogues.sectionCards.notFound',
          defaultMessage: 'Sorry, but nothing can be found.'
        })}
        image={CrossroadImg}
      /> : <DialoguesCardSection
        showLoader={loading.includes(getDialogues)}
        cards={dialogues}
        onClickCard={handleOpenDialogue}
      />}
    </div>;
  }

  return <section className={'dialogues container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="dialogues__title"><ChatIcon className="dialogues__icon"/>
          <FormattedMessage
            id={'gritx.dialogues.title'}
            defaultMessage={'dialogues'}
          />
        </h1>
        <div className="dialogues__header">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.dialogues.create',
              defaultMessage: 'Create'
            })}
            className={'dialogues__button'}
            variant={ButtonVariant.Primary}
            onClick={onCreateDialogue}
          />
        </div>
        {
          loading.includes(getDialogues) || loading.includes(removeDialogue) ? <Loader/> : renderDialoguesCards()
        }
        <DialogueModalWindow
          dialogueCard={newDialogue}
          title={'Create a new Dialogue'}
          onSave={handleCreateDialogue}
          onClose={handleCloseDialogue}
          show={isShowCreateDialogue}
        />
      </>
    }
  </section>;
};
