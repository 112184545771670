import {useIntl} from 'react-intl';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Debounce from 'lodash/debounce';
import cn from 'classnames';

import {contentAsyncActions, getArticle, getPublicArticle, getTranslations} from 'redux/pageAsyncActions';
import {IStore} from 'redux/interface';

import Article from 'components/article';
import {ButtonVariant} from '@wholesalechange/chatcomponent';
import {GritxButton} from '@wholesalechange/chatcomponent';
import NotFoundInfo from 'components/not-found-info';
import Loader from 'components/loader';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import CrossroadImg from 'assets/image/library/skill-crossroad.svg';

import './styles.scss';

export const PublicContent: React.FunctionComponent = () => {
  const {
    content: {
      article
    },
    status: {
      loading,
      errors
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useGetTranslations({
    translationKey: TranslationKeys.Content
  });

  function handleScroll() {
    return Debounce((e) => {
      setCurrentScrollPosition(e.target.scrollingElement.scrollTop);
    }, 100);
  }

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substr(1);

      window.scrollTo(0, 0);
      dispatch(contentAsyncActions[getPublicArticle](id));
    }
  }, [location]);

  useEffect(() => {
    if (currentScrollPosition > 500 && currentScrollPosition <= prevScrollPosition) {
      setShowBackToTopButton(true);
    } else {
      setShowBackToTopButton(false);
    }
    setPrevScrollPosition(currentScrollPosition);
  }, [currentScrollPosition]);

  function handleScrollToTop() {
    window.scrollTo(0, 0);
  }

  function renderBackToTop() {
    return showBackToTopButton
      ? <div className="content__back-to-top">
        <GritxButton
          title={intl.formatMessage({
            id: 'gritx.content-page.back-to-top',
            defaultMessage: 'Back to top'
          })}
          variant={ButtonVariant.Light}
          onClick={handleScrollToTop}
        />
      </div>
      : null;
  }

  function renderArticle() {
    if (loading.includes(getArticle)) {
      return <div className="content__view">
        <div className="content__data"><Loader nested/></div>
      </div>;
    }

    if (errors[getArticle]?.error.message.includes('500') || errors[getArticle]?.error.message.includes('400')) {
      return <div className="content__view">
        <div className="content__data">
          <NotFoundInfo
            title={intl.formatMessage({
              id: 'gritx.content-page.notFoundCard',
              defaultMessage: 'Sorry, we can\'t find the card you are looking for.'
            })}
            image={CrossroadImg}/>
        </div>
      </div>;
    }

    if (article) {
      return <div className="content__view">
        <div className="content__data">
          <Article card={article}/>
        </div>
      </div>;
    }

    return null;
  }

  return <div className={cn('content', 'content--no-items', {
    'content--no-article': article === null && !loading.includes(getArticle)
  })}>
    {
      !loading.includes(getTranslations) && <>
        {renderBackToTop()}
        <div className="content__wrapper">
          {renderArticle()}
        </div>
      </>
    }
  </div>;
};
