import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import translationReducer from './translation/reducer';
import statusReducer from './status/reducer';
import contentReducer from './content/reducer';
import feedbackReducer from './feedback/reducer';
import mediaReducer from './media/reducer';
import dialogueReducer from './dialogue/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  translation: translationReducer,
  status: statusReducer,
  content: contentReducer,
  feedback: feedbackReducer,
  media: mediaReducer,
  dialogue: dialogueReducer
});

const middleware = process.env.NODE_ENV === 'production'
  ? applyMiddleware(thunk)
  : composeWithDevTools(
    applyMiddleware(thunk)
  );

const store = createStore(
  rootReducer, {},
  middleware
);

export default store;
