export enum RoutesUrl {
  Home = '/',
  Logout = '/logout',
  Library = '/library',
  Materials = '/materials',
  Backpack = '/backpack',
  Chat = '/chat',
  Xpeditions = '/xpedition',
  Xpedition = '/xpedition/:xpeditionId/:restart?',
  Settings = '/settings',
  Content = '/content',
  PublicContent = '/public-content',
  Callback = '/callback',
  Dialogues = '/dialogues',
  Dialogue = '/dialogue/:dialogueId',
}

export enum TranslationKeys {
  Library = 'library',
  Materials = 'materials',
  Backpack = 'backpack',
  Chat = 'chat',
  Settings = 'user-settings',
  Content = 'content-page',
  Error = 'error',
  Footer = 'footer',
  Common = 'common',
  Home = 'home',
  Toasts = 'toasts',
  Dialogues = 'dialogues',
  Dialogue = 'dialogue'
}

export const firstAuthorizedRoute = RoutesUrl.Chat;

export const routeTranslationKeys: { [key: string]: string } = {
  [RoutesUrl.Library]: TranslationKeys.Library,
  [RoutesUrl.Materials]: TranslationKeys.Materials,
  [RoutesUrl.Backpack]: TranslationKeys.Backpack,
  [RoutesUrl.Chat]: TranslationKeys.Chat,
  [RoutesUrl.Xpeditions]: TranslationKeys.Chat,
  [RoutesUrl.Xpedition]: TranslationKeys.Chat,
  [RoutesUrl.Settings]: TranslationKeys.Settings,
  [RoutesUrl.Content]: TranslationKeys.Content,
  [RoutesUrl.Dialogues]: TranslationKeys.Dialogues,
  [RoutesUrl.Dialogue]: TranslationKeys.Dialogue
};
