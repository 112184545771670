import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import {useEffect, useState} from 'react';
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';

interface IUseSpeechRecognition {
  browserSupportsSpeechRecognition: boolean
  listening: boolean
  loading: boolean
  startListening: (language: string) => void
  stopListening: () => void
}

interface IUseSpeechRecognitionProps {
  onResult: (transcript: string) => void
}

export const useVideoSpeechRecognition = ({onResult} : IUseSpeechRecognitionProps): IUseSpeechRecognition => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    resetTranscript,
    finalTranscript,
    interimTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const {SpeechRecognition: AzureSpeechRecognition} = createSpeechServicesPonyfill({
    credentials: {
      region: process.env.REACT_APP_AZURE_REGION,
      subscriptionKey: process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY
    }
  });

  const convertLang = (lang: string) => {
    switch (lang.toLowerCase()) {
      case 'en':
        return 'en-US';
      case 'ru':
        return 'ru-RU';
      case 'es':
        return 'es-US';
      case 'zh':
        return 'zh-CN';
      default:
        return lang;
    }
  };

  const startListening = (language: string) => {
    setIsLoading(true);
    try {
      SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
      SpeechRecognition.startListening({
        continuous: true,
        language: convertLang(language || '')
      }).then(() => {
        setIsConnected(true);
        setIsLoading(false);
        console.log('speech recognition has been started');
      });
    } catch (e) {
      console.log('startListening error:');
      console.log(e);
      setIsLoading(false);
      setIsConnected(false);
    }
  };

  const stopListening = () => {
    try {
      SpeechRecognition.abortListening();
      setIsConnected(false);
    } catch (e) {
      console.log('stopListening error');
      console.log(e);
    }
  };

  useEffect(() => {
    if (finalTranscript !== '') {
      onResult(finalTranscript);
      resetTranscript();
    }
  }, [interimTranscript, finalTranscript]);

  return {
    browserSupportsSpeechRecognition,
    listening: isConnected,
    loading: isLoading,
    startListening,
    stopListening
  };
};
