import React, {useState} from 'react';
import ModalWindow from '../modal-window';
import {useIntl} from 'react-intl';
import {GritxButton} from '@wholesalechange/chatcomponent';
import {ButtonVariant} from '@wholesalechange/chatcomponent';
import Input from '../input';
import {IDialogueCard} from '../../redux/dialogue/interfaces';

import './style.scss';

interface IDialogueModalWindow {
  dialogueCard: IDialogueCard
  title: string
  onSave: (dialogue: IDialogueCard) => void
  onClose: () => void
  show: boolean
}

export const DialogueModalWindow = (
  {
    dialogueCard,
    title,
    onSave,
    onClose,
    show
  }: IDialogueModalWindow
): JSX.Element => {
  const intl = useIntl();
  const [dialogue, setDialogue] = useState<IDialogueCard>(dialogueCard);

  function handleClose() {
    setDialogue(dialogueCard);
    onClose();
  }

  function handleSave() {
    onSave(dialogue);
  }

  function handleChange(type: string): (e: string) => void {
    return (e) => {
      const newDialogue = {
        ...dialogue,
        [type]: e
      };

      setDialogue(newDialogue);
    };
  }

  const content = () => {
    return <div className={'dialogue-form__container'}>
      <div className={'form-dialogue'}>
        <div className="form-dialogue__header">
          <h2 className="form-dialogue__title">{title}</h2>
        </div>
        <div className="form-dialogue__body">
          <Input
            required
            type="text"
            value={dialogue.name}
            label={intl.formatMessage({
              id: 'gritx.footer.dialogueForm.name.label',
              defaultMessage: 'Name'
            })}
            onChange={handleChange('name')}
          />
        </div>
      </div>
    </div>;
  };

  const formsButtons = () => {
    return <div className="dialogue-form__footer">
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.cancel',
          defaultMessage: 'Cancel'
        })}
        variant={ButtonVariant.Light}
        className="dialogue-form__button"
        onClick={handleClose}
      />
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.save',
          defaultMessage: 'Save'
        })}
        variant={ButtonVariant.Primary}
        className="dialogue-form__button"
        onClick={handleSave}
      />
    </div>;
  };

  return <ModalWindow
    show={show}
    className="dialogue-form__modal"
    onHide={handleClose}
    footerElement={formsButtons()}
  >
    {content()}
  </ModalWindow>;
};
