import React, {useEffect, useState} from 'react';
import {
  dialogueAsyncActions,
  getDialogue,
  getTranslations
} from '../../redux/pageAsyncActions';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {GritxButton} from '@wholesalechange/chatcomponent';
import {ButtonVariant} from '@wholesalechange/chatcomponent';

import './styles.scss';
import {DialogueChat} from '../../components/dialogue-chat/DialogueChat';
import {dialogueClearedAction} from '../../redux/dialogue/actions';

export const Dialogue: React.FunctionComponent = () => {
  const {
    dialogue: {
      dialogue
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const {dialogueId} = useParams();
  const [isShowParticipants, setShowParticipants] = useState<boolean>(false);

  function onShowParticipantsPanel() {
    setShowParticipants(!isShowParticipants);
  }

  function handleHideParticipants() {
    setShowParticipants(false);
  }

  useEffect(() => {
    if (dialogueId) {
      dispatch(dialogueAsyncActions[getDialogue](Number(dialogueId)));
    }

    return () => {
      dispatch(dialogueClearedAction());
    };
  }, [dialogueId]);

  function renderBreadcrumbs() {
    return <div className="breadcrumbs">
      <Link className="breadcrumbs__previous" to="/Dialogues">
        <FormattedMessage
          id={'gritx.content-page.Dialogues.breadcrumb.previous'}
          defaultMessage='Dialogues'
        />
      </Link> - <span className="breadcrumbs__current">
        {dialogue?.name}
      </span>
    </div>;
  }

  return <div className="dialogue">
    {
      !loading.includes(getTranslations) && <>
        {renderBreadcrumbs()}
        <div className="dialogue__header">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.dialogue.participants',
              defaultMessage: 'Participants'
            })}
            className={'dialogue__button'}
            variant={ButtonVariant.Primary}
            onClick={onShowParticipantsPanel}
          />
        </div>
        <DialogueChat dialogue={dialogue}
          isShowParticipants={isShowParticipants}
          onHideParticipants={handleHideParticipants}/>
      </>
    }
  </div>;
};
