import {useEffect, useState} from 'react';
import {CompatClient, Stomp} from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {StompSubscription} from '@stomp/stompjs/esm6/stomp-subscription';
import {StompHeaders} from '@stomp/stompjs/src/stomp-headers';
import {IFrame} from '@stomp/stompjs/src/i-frame';
import {useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {IParticipant} from '../../redux/dialogue/interfaces';

export interface IUseVideoWebsocket {
  changeVideoCallSettings: (videoCallId: number, speechRecognition: boolean, language: string) => void
  sendMessage: (type: string, dialogueId: number, videoCallId: number | undefined, text: string, participant: IParticipant, showInHistory: boolean) => void
  connected: boolean
  loading: boolean
  subscribe: (topic: string) => void
  disconnect: () => void
}

export interface IVideoCallSettings {
  videoCallId: number
  speechRecognition: boolean
  language: string
}

interface IUseVideoWebsocketProps {
  url: string
  headers: StompHeaders
  subscribeHeaders: StompHeaders
  topics: string[]
  onMessage: (msg: IVideoCallSettings, topic: string) => void
  options: SockJS.Options
  heartbeat: number
  heartbeatIncoming: number,
  heartbeatOutgoing: number,
  debug: boolean,
  onConnect: () => void
  onConnectFailure?: (error: string) => void
  onDisconnect: () => void
  autoReconnect: boolean
  getRetryInterval: (count: number) => number
}

export const useVideoWebSocket = (props: IUseVideoWebsocketProps): IUseVideoWebsocket => {
  const {
    auth: {userProfile}
  } = useSelector((state: IStore) => state);
  const [compatClient, setCompatClient] = useState<CompatClient>();
  const [connected, setConnected] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<{[key: string]: StompSubscription}>({});
  const [retryCount, setRetryCount] = useState<number>(0);
  const [explicitDisconnect, setExplicitDisconnect] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultProps = {
    onConnect: () => {
      console.log('connect');
    },
    onDisconnect: () => {
      console.log('disconnect');
    },
    getRetryInterval: (count: number) => {
      return 1000 * count;
    },
    options: {},
    headers: {},
    subscribeHeaders: {},
    autoReconnect: true,
    debug: false,
    heartbeat: 10000
  };

  function initStompClient(): CompatClient {
    const client = Stomp.over(new SockJS(props.url, null, props.options));

    client.heartbeat.outgoing = props.heartbeat;
    client.heartbeat.incoming = props.heartbeat;

    if (props.heartbeatIncoming) {
      client.heartbeat.incoming = props.heartbeatIncoming;
    }
    if (props.heartbeatOutgoing) {
      client.heartbeat.outgoing = props.heartbeatIncoming;
    }
    if (!props.debug) {
      client.debug = () => {
        console.log('debug');
      };
    }
    setCompatClient(client);

    return client;
  }

  function processMessage(msgBody: string) {
    try {
      return JSON.parse(msgBody);
    } catch (e) {
      return msgBody;
    }
  }

  function subscribe(topic: string) {
    if (compatClient && !subscriptions.topic) {
      const sub = compatClient.subscribe(topic, (msg) => {
        props.onMessage(processMessage(msg.body), msg.headers.destination);
      }, props.subscribeHeaders);

      setSubscriptions({...subscriptions, topic: sub});
    }
  }

  function log(msg: string) {
    if (props.debug) {
      console.log(msg);
    }
  }

  function cleanUp() {
    setConnected(false);
    setRetryCount(0);
    setSubscriptions({});
  }

  function connect() {
    setLoading(true);
    const client = initStompClient();

    client.connect(props.headers, () => {
      setConnected(true);
      props.topics.forEach((topic) => {
        subscribe(topic);
      });
      props.onConnect();

      setLoading(false);
    }, (error: IFrame) => {
      if (error) {
        if (props.onConnectFailure) {
          props.onConnectFailure(error.body);
        } else {
          log(error.body);
        }
      }
      if (connected) {
        cleanUp();
        // onDisconnect should be called only once per connect
        props.onDisconnect();
      }
      if (props.autoReconnect && !explicitDisconnect) {
        const retry = retryCount + 1;

        setRetryCount(retry);
        const timeout = setTimeout(connect, props.getRetryInterval(retry));

        setTimeoutId(timeout);
      }

      setLoading(false);
    });
  }

  function unsubscribe(topic: string) {
    const sub = subscriptions[topic];

    sub.unsubscribe();
    delete subscriptions[topic];
    setSubscriptions(subscriptions);
  }

  function disconnect() {
    // On calling disconnect explicitly no effort will be made to reconnect
    // Clear timeoutId in case the component is trying to reconnect
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setExplicitDisconnect(true);
    if (connected && compatClient) {
      Object.keys(subscriptions).forEach(topic => {
        unsubscribe(topic);
      });
      compatClient.disconnect(() => {
        cleanUp();
        props.onDisconnect();
        log('Stomp client is successfully disconnected!');
      });
    }
  }

  function sendMessage(topic: string, msg: string, optHeaders: StompHeaders) {
    const sendHeaders: StompHeaders = {...props.headers, ...optHeaders};

    if (compatClient && connected) {
      compatClient.send(topic, sendHeaders, msg);
    } else {
      throw new Error('Send error: SockJsClient is disconnected');
    }
  }

  useEffect(() => {
    connect();
  }, []);

  function changeVideoCallSettings(videoCallId: number, speechRecognition: boolean, language: string) {
    if (!compatClient) {
      return;
    }
    const message = {
      videoCallId: videoCallId,
      speechRecognition: speechRecognition,
      language: language
    };

    sendMessage('/app/video', JSON.stringify(message), {});
  }

  function sendVideoCallMessage(type: string, dialogueId: number, videoCallId: number | undefined, text: string,
    participant: IParticipant, showInHistory: boolean) {
    if (!compatClient || !userProfile) {
      return;
    }
    const message = {
      dialogueID: dialogueId,
      message: {
        sender: {
          id: participant.userId,
          type: 'CLIENT',
          name: userProfile.email,
          avatar: participant.participantAvatar,
          participantName: participant.participantName
        },
        dialogueId: dialogueId,
        type: type,
        text: text,
        videoCallId: videoCallId,
        showInHistory: showInHistory
      }
    };

    sendMessage('/app/message', JSON.stringify(message), {});
  }

  return {
    changeVideoCallSettings: changeVideoCallSettings,
    sendMessage: sendVideoCallMessage,
    connected: connected,
    loading: loading,
    subscribe: subscribe,
    disconnect: disconnect
  };
};
