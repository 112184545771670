import {ThunkAction} from 'redux-thunk';
import {IStore} from '../interface';
import {Action} from 'redux';
import {
  addBotToDialogue, addUsersToDialogue, blockParticipant,
  createDialogue, getBots,
  getDialogue, getDialogueParticipant,
  getDialogueParticipants,
  getDialogues, getXpeditions,
  removeDialogue,
  removeParticipantFromDialogue, restartXpedition,
  setDialogueName, startXpedition, unlockParticipant
} from '../pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from '../status/actions';
import {
  addBotToDialogueApi,
  addUsersToDialogueApi,
  createDialogueApi,
  getBotListApi,
  getDialogueApi,
  getDialogueParticipantsApi,
  getDialoguesApi,
  removeDialogueApi,
  removeParticipantFromDialogueApi,
  setDialogueNameApi,
  getDialogueParticipantApi,
  blockParticipantApi,
  unlockParticipantApi,
  startXpeditionApi,
  restartXpeditionApi,
  getXpeditionsApi
} from '../api/dialogue';
import {
  botsLoadedAction,
  dialogueLoadedAction, participantLoadedAction,
  participantsLoadedAction,
  setDialogues, xpeditionsLoadedAction
} from './actions';
import {errorHandler} from '../errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {SystemLanguage} from '../../utils/constants/constants';
import {IDialogueCard} from './interfaces';

export const loadDialoguesAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getDialogues;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getDialoguesApi(locale);

      dispatch(finishLoadingAction(actionKey));
      dispatch(setDialogues(data));
    } catch (e) {
      console.log('LoadDialoguesAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadDialoguesAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadDialogueAction = (id: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client,
      userProfile
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getDialogueApi(id, userProfile?.language || SystemLanguage.English);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(dialogueLoadedAction(data));
    } catch (e) {
      console.log('loadDialogueAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadDialogueAction,
        actionKey,
        error: e,
        currentAction: loadDialogueAction(id)
      }));
    }
  }
});

export const setDialogueNameAction = (dialogue: IDialogueCard): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = setDialogueName;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await setDialogueNameApi(dialogue);
      dispatch(loadDialogueAction(dialogue.id));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('setDialogueNameAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.SetDialogueNameAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const createDialogueAction = (dialogue: IDialogueCard): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = createDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await createDialogueApi(dialogue);
      dispatch(loadDialoguesAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('CreateDialogueAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.CreateDialogueAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const removeDialogueAction = (dialogue: IDialogueCard): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = removeDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await removeDialogueApi(dialogue.id);
      dispatch(loadDialoguesAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('removeDialogueAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.RemoveDialogueAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadDialogueParticipantsAction = (dialogueId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getDialogueParticipants;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getDialogueParticipantsApi(dialogueId);

      dispatch(finishLoadingAction(actionKey));
      dispatch(participantsLoadedAction(data));
    } catch (e) {
      console.log('LoadDialogueParticipantsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadDialogueParticipantsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadDialogueParticipantAction = (userId: number, dialogueId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getDialogueParticipant;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getDialogueParticipantApi(userId, dialogueId);

      dispatch(finishLoadingAction(actionKey));
      dispatch(participantLoadedAction(data));
    } catch (e) {
      console.log('LoadDialogueParticipantAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadDialogueParticipantAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadBotsAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getBots;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getBotListApi();

      dispatch(finishLoadingAction(actionKey));
      dispatch(botsLoadedAction(data));
    } catch (e) {
      console.log('LoadBotsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadBotsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const addBotToDialogueAction = (botId: number, dialogueId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = addBotToDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await addBotToDialogueApi(botId, dialogueId);
      dispatch(loadDialogueParticipantsAction(dialogueId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('AddBotToDialogueAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.AddBotToDialogueAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const addUsersToDialogueAction = (emails: string, dialogueId: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = addUsersToDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await addUsersToDialogueApi(emails, dialogueId);
      dispatch(loadDialogueParticipantsAction(dialogueId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('AddUsersToDialogueAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.AddUsersToDialogueAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const removeParticipantFromDialogueAction = (participantId: number, dialogueId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = removeParticipantFromDialogue;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await removeParticipantFromDialogueApi(participantId);
      dispatch(loadDialogueParticipantsAction(dialogueId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('RemoveParticipantFromDialogueAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.RemoveParticipantFromDialogueAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const blockParticipantAction = (participantId: number, dialogueId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = blockParticipant;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await blockParticipantApi(participantId);
      dispatch(loadDialogueParticipantsAction(dialogueId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('BlockParticipantAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.BlockParticipantAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const unlockParticipantAction = (participantId: number, dialogueId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = unlockParticipant;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await unlockParticipantApi(participantId);
      dispatch(loadDialogueParticipantsAction(dialogueId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('UnlockParticipantAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.UnlockParticipantAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const startXpeditionAction = (xpeditionId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = startXpedition;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data: {value: dialogId} } = await startXpeditionApi(xpeditionId, locale);

      dispatch(loadDialogueAction(dialogId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('StartXpeditionAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.StartXpeditionAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const restartXpeditionAction = (xpeditionId: number):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = restartXpedition;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data: {value: dialogId} } = await restartXpeditionApi(xpeditionId, locale);

      dispatch(loadDialogueAction(dialogId));
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('RestartXpeditionAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.RestartXpeditionAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadXpeditionsAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getXpeditions;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getXpeditionsApi(locale);

      dispatch(finishLoadingAction(actionKey));
      dispatch(xpeditionsLoadedAction(data));
    } catch (e) {
      console.log('LoadXpeditionsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadXpeditionsAction,
        actionKey,
        error: e
      }));
    }
  }
});
