import {IDialogueCard} from '../../redux/dialogue/interfaces';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoadFile} from '../../utils/hooks/useLoadFile';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {
  contentAsyncActions,
  dialogueAsyncActions,
  getArticle,
  getDialogueParticipant
} from '../../redux/pageAsyncActions';
import DialogueVideo from '../dialogue-video';
import DialogueParticipants from '../dialogue-participants';
import {BotActionType, BotType, IBotAction, MessageType} from '../../utils/hooks/use-chat-bot/interfaces';
import BotIcon from '../../assets/image/user-settings/BotDefaultAvatar.png';
import UserIcon from '../../assets/image/user-settings/userDefaultAvatar.png';
import {closeArticle} from '../../redux/content/actions';
import Loader from '../loader';
import Article from '../article';
import {loadArticleAction} from '../../redux/content/contentAsyncActions';
import {BotChat, BotMode, GritxButton, ButtonVariant, BotNameMode} from '@wholesalechange/chatcomponent';
import {useDictionary} from '../../utils/hooks/useDictionary';
import {useChatBotNew} from '../../utils/hooks/useChatBotNew';
import './styles.scss';
import {BotContent} from '../bot-chat/BotContent';

interface IDialogueChat {
  dialogue?: IDialogueCard | null,
  isShowParticipants?: boolean | undefined,
  onHideParticipants?: () => void;
  onAddBot?: () => void;
}

export const DialogueChat: FunctionComponent<IDialogueChat> = ({dialogue, isShowParticipants, onHideParticipants, onAddBot}: IDialogueChat) => {
  const intl = useIntl();
  // ----------
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const [isHost, setIsHost] = useState<boolean | null>(null);
  const [avatar, setAvatar] = useState<string>(UserIcon);
  const [needReloadHistory, setNeedReloadHistory] = useState(false);
  const [isShowContent, setIsShowContent] = useState(true);
  const {
    auth: {userProfile},
    content: {article},
    status: {loading},
    dialogue: {participant, participants}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {getFileUrl, getFileUrlAsync} = useLoadFile();

  const dictionary = useDictionary();
  const botMode = BotMode.FullHistory;
  const dialogueChat = useChatBotNew(BotType.Dialogue, botMode);

  useEffect(() => {
    const avatarId = dialogue?.avatar || userProfile?.dialogAvatarId;

    if (avatarId) {
      getFileUrl(avatarId, (url: string) => {
        setAvatar(url);
      });
    } else {
      setAvatar(BotIcon);
    }
    if (dialogue) {
      dialogueChat.dialogueMethods.getDialogById(dialogue.id);
    }
  }, [dialogue]);

  async function refreshDialogueHistory() {
    if (dialogue) {
      await dialogueChat.dialogueMethods.refreshDialogueHistory();
    }
  }

  useEffect(() => {
    if (needReloadHistory) {
      setNeedReloadHistory(false);

      refreshDialogueHistory();
    }
  }, [participants]);

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useEffect(() => {
    if (dialogue && userProfile) {
      dispatch(dialogueAsyncActions[getDialogueParticipant](userProfile.id, dialogue.id));
    }
  }, [userProfile, dialogue]);

  useEffect(() => {
    if (participant) {
      setIsHost(participant.participantRole === 'ADMIN');
    }
  }, [participant]);

  const pushUserMessage = (text: string) => {
    if (dialogue && participant) {
      dialogueChat.sendMessage(MessageType.Text, participant, {text});
    }
  };

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleVideoCall() {
    setIsShowVideo(true);
    if (onHideParticipants) {
      onHideParticipants();
    }
    dispatch(closeArticle());
    setIsShowContent(false);
  }

  function handleAddBot() {
    setNeedReloadHistory(true);
  }

  function handleLeaveVideo() {
    setIsShowVideo(false);
    setIsVideoStarted(false);
  }

  function handleStartVideo() {
    setIsVideoStarted(true);
  }

  function handleSendOverlay(overlayAnswer: IBotAction) {
    if (!dialogue || !participant) {
      return;
    }
    const actionButton = overlayAnswer;

    dialogueChat.sendMessage(MessageType.Action, participant, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  const handleSendMessage = (message: string) => {
    pushUserMessage(message.trim());
  };

  async function loadMore() {
    if (dialogue && !dialogueChat.isLoading) {
      await dialogueChat.dialogueMethods.fetchMore();
    }
  }

  function handleShowArticle(code: string) {
    dispatch(contentAsyncActions[getArticle](code));
    setIsShowContent(true);
  }

  function handleClickAction(actionButton: IBotAction) {
    if (!dialogue || !participant) {
      return;
    }
    const actionBody = JSON.parse(actionButton.actionBody);

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setIsShowContent(true);
        dialogueChat.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
        // setIsShowXpeditionOverlay(false);
        dialogueChat.sendMessage(MessageType.Action, participant, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleShowExternalOverlay() {
    if (article) {
      dispatch(closeArticle());
    }
    setIsShowContent(false);
  }

  function renderVideoCallButton() {
    if (isHost === null) {
      return <></>;
    }
    if (isHost) {
      return <GritxButton
        title={intl.formatMessage({
          id: 'gritx.chat.start.videocall',
          defaultMessage: 'Start a video call'
        })}
        disabled={isVideoStarted}
        variant={ButtonVariant.Outline}
        className="dialogue-chat__video-btn"
        onClick={handleVideoCall}
      />;
    }

    return <GritxButton
      title={intl.formatMessage({
        id: 'gritx.chat.join.videocall',
        defaultMessage: 'Join a video call'
      })}
      variant={ButtonVariant.Outline}
      disabled={isVideoStarted}
      className="dialogue-chat__video-btn"
      onClick={handleVideoCall}
    />;
  }

  function renderDialogueVideo() {
    return dialogue && isShowVideo && isHost !== null
      ? <DialogueVideo dialogue={dialogue} isHost={isHost} onLeaveVideoCall={handleLeaveVideo} onStartVideoCall={handleStartVideo}/>
      : <></>;
  }

  function handleCloseArticle() {
    dispatch(closeArticle());
  }

  function renderBotContent() {
    return <div className="dialogue-window__content">
      <DialogueParticipants dialogue={dialogue}
        isShowParticipants={isShowParticipants || false}
        onAddBot={handleAddBot}/>
      {renderDialogueVideo()}
      {isShowContent && <BotContent botAnswer={dialogueChat.botAnswer}/>}
    </div>;
  }

  function renderButtons() {
    return <GritxButton
      title={intl.formatMessage({
        id: 'gritx.chat.helpButton',
        defaultMessage: 'Help'
      })}
      variant={ButtonVariant.Outline}
      className="dialogue-chat__help-btn"
      onClick={handleHelp}
    />;
  }

  return userProfile && <div className="dialogue-window">
    <div className="dialogue-window__wrapper">
      <BotChat isLoading={dialogueChat.isLoading}
        isTyping={dialogueChat.isTyping}
        chatAvatar={avatar}
        toolbarButtons={[renderVideoCallButton(), renderButtons()]}
        messages={dialogueChat.messages || []}
        onSend={handleSendMessage}
        onSendOverlay={handleSendOverlay}
        onClickAction={handleClickAction}
        currentUser={userProfile}
        onLoadMore={loadMore}
        onShowArticle={handleShowArticle}
        onShowExternalOverlay={handleShowExternalOverlay}
        dictionary={dictionary}
        botMode={botMode}
        botType={BotType.Dialogue}
        isConnected={dialogueChat.isConnected}
        botNameMode={BotNameMode.BOT}
        getFileUrl={getFileUrlAsync}/>
      {renderBotContent()}
    </div>
  </div>;
};
